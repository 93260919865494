import moment from "moment";

import IconAmericanExpress from '@/images/user/ideal-pay/IconAmericanExpress.svg';
import IconMasterCard from '@/images/user/ideal-pay/IconMasterCard.svg';
import IconVisa from '@/images/user/ideal-pay/IconVisa.svg';

export const hanselTimeout = 1000;

export const listCardType = [
    {
        type: 'mastercard',
        image: IconMasterCard,
    },
    {
        type: 'amex',
        image: IconAmericanExpress,
    },
    {
        type: 'visa',
        image: IconVisa,
    },
]

export const listPekerjaan = ["Karyawan", "Professional", "Pengusaha"];
export const listPendidikan = [
    "Tidak/Belum Sekolah",
    "Tidak Tamat SD/Sederajat",
    "Tamat SD/Sederajat",
    "SLTP/Sederajat",
    "SLTA/Sederajat",
    "Akademi/Diploma III",
    "Strata I atau Diploma IV",
    "Strata II",
    "Strata III",
];
export const listJumlahAnak = ["0", "1", "2", "3", "4", "5", ">5"];
export const listJumlahTanggungan = ["0", "1", "2", "3", "4", "5", ">5"];

export const listHubungan = ["Ayah", "Ibu", "Kakak", "Adik", "Saudara"];

export const listBidangUsaha = ["Finansial", "Makanan", "Property"];
export const listBulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
];

export const listTahun = () => {
    let list = [];

    for (let i = 0; i < 20; i++) {
        list?.push(+moment().year() - i);
    }

    return list;
};

export const listOptionKPR = [
    { id: 1, remark: 'Saya ingin merubah nominal DP' },
    { id: 2, remark: 'Ingin merubah produk KPR' },
    { id: 3, remark: 'Saya ingin merubah tipe properti' },
    { id: 4, remark: 'Proses terlalu lama' },
    { id: 5, remark: 'Sudah mendapat persetujuan KPR diluar aplikasi IDEAL' },
]

export const listOptionRefinance = [
    { id: 1, remark: 'Ingin mengubah produk kredit' },
    { id: 2, remark: 'Ingin mengubah info properti' },
    { id: 3, remark: 'Ingin mengubah preferensi ' },
    { id: 4, remark: 'Sudah mendapat persetujuan pinjaman multiguna di luar aplikasi Ideal' },
]

export const predraftStatus = {
    changePreference: 'change preference',
    continue: 'continue',
    draft: 'draft',
    done: 'done',
    pending: 'pending',
    submitted: 'submitted',
}

export const searchTimeout = 1000;
