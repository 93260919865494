/* eslint-disable react/no-unknown-property */
'use client'

// Import Library
import dynamic from "next/dynamic";

import { useSearchParams } from "next/navigation";
import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Import Custom Library
import { hanselTimeout } from "@/configs/MasterData";
import { logEventNudges } from "@/core/helpers/analytics";
import { setNeedSignUp } from "@/core/store/reducer/loginSlice";

// Import Component
import LoadingSpinner from "@/core/components/Loader/LoadingSpinner";

const BankPartners = dynamic(() => import('@/components/home/BankPartners'), {
    loading: () => <LoadingSpinner classGroup="h-[604px] relative w-full" height={48} width={48} />,
});
const Banner = dynamic(() => import('@/components/home/Banner'), {
    loading: () => <LoadingSpinner classGroup="h-[816px] relative w-full" height={48} width={48} />,
});
const Comparison = dynamic(() => import('@/components/home/Comparison'), {
    loading: () => <LoadingSpinner classGroup="h-[1386px] relative w-full" height={48} width={48} />,
});
const DeveloperPartners = dynamic(() => import('@/components/home/DeveloperPartners'), {
    loading: () => <LoadingSpinner classGroup="h-[466px] relative w-full" height={48} width={48} />,
});
const Media = dynamic(() => import('@/components/home/Media'), {
    loading: () => <LoadingSpinner classGroup="h-[522px] relative w-full" height={48} width={48} />,
});
const PropertyCategory = dynamic(() => import('@/components/home/PropertyCategory'), {
    loading: () => <LoadingSpinner classGroup="h-[654px] relative w-full" height={48} width={48} />,
});
const Summary = dynamic(() => import('@/components/home/Summary'), {
    loading: () => <LoadingSpinner classGroup="h-[356px] relative w-full" height={48} width={48} />,
});

export default function Home(props) {
    const dispatch = useDispatch();

    const refNudgesTimeout = useRef(null);

    const searchParams = useSearchParams();

    const referral = searchParams.get('referral');

    const CheckNudges = () => {
        refNudgesTimeout.current = setTimeout(() => {
            logEventNudges('HP_LS');
            logEventNudges('lp_main_web');

            console.log('Load Hansel Run');
        }, hanselTimeout);
    }

    useEffect(() => {
        if (sessionStorage.getItem('firstLoad') === '1') {
            CheckNudges();
        } else {
            window.addEventListener('load', CheckNudges);
        }

        return () => {
            window.removeEventListener('load', CheckNudges);

            clearTimeout(refNudgesTimeout?.current);
        }
    }, []);

    useEffect(() => {
        if (referral) {
            dispatch(setNeedSignUp(true));
        }
    }, [referral]);

    return (
        <Fragment>
            <Banner />
            <Summary summary={props?.summary} />
            <PropertyCategory enableIdealPay={+props?.enableIdealPay} />
            <BankPartners bank={props?.bank} />
            <Comparison list={props?.comparison} />
            <DeveloperPartners developer={props?.developer} />
            <Media article={props?.article} />
        </Fragment>
    );
}
